// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	localeString: "AU",
	bookmakers: [
		"bet365", "topsport", "bluebet", "betfair", "tab","pointsbet_au"
	],
	sportExclusiveBookmakers: {
		"AFLW": "topsport",
		"AFL": "bet365",
		"NRL": "bet365",
		"NBA": "betfair",
		"NFL": "bet365",
		"CFB": "bet365",
		"CBB": "tab",
		"MLB": "tab",
		"BBL": "betfair",
		"NHL": "bet365",
		"TEN": "tab",
		"EPL": "bet365",
		"ESP": "bet365",
		"MLS": "bet365",
		"ALG": "bet365",
		"SRU": "bet365",
		"TRC": "bet365",
		"SWC": "betfair",
		"GOLF": "topsport",
		"SOO": "tab",
	},
    rollbarAccessToken: "04017e4085cc4a199fd5192d2f720158",
    futuresBookmakerOverrideSports: ["EURO", "CFB", "CBB", "MLS", "SWC", "RACING", "EPL", "ESP", "AFL", "NRL", "NFL", "NHL"],
	auth0Domain: "auth.statsinsider.com.au",
	auth0ClientId: "mtlxSuWL1mnFcPjsqT0twz8mYWPmpPhn",
	auth0AudienceDomain: "statsinsider.au.auth0.com",
	defaultLogo: "https://cdn.ciphersports.io/assets/statsinsider_2022_logo_white.svg",
	availableSports: ["SOO", "AFL", "NRL", "NBA", "NFL", "CFB", "CBB", "MLB", "BBL", "NHL", "TEN", "EPL", "ESP", "MLS", "GOLF", "SWC"],
	officialLogoSports: ["NBA", "NFL", "CFB", "CBB", "MLB", "NHL", "MLS", "RACING"],
	liveCoverageSports: ["EURO", "AFL", "NRL", "NBA", "NFL", "CFB", "CBB", "MLB", "NHL", "TEN", "EPL", "ESP", "MLS", "SWC"],
	oddsDisplayFormat: "decimal",
    temperatureDisplay: "celsius",
	gaKey: "G-K3JKTR7MLX",
	gtmKey: "GTM-MSVLD26",
	apiDomain: "https://levy-edge.statsinsider.com.au",
	blockDomain: "https://content-blocks-node.azurewebsites.net",
	dimersApiDomain: "https://cdn.dimers.com",
    dimersUncachedDomain: "https://api.dimers.com",
    dimersGeoDomain: "https://apidev.statsinsider.com.au",
	sharpSportsKey: "e0692325b2e0e30257751f10b3d5d310e4327a2d",
	siteTitle: "Stats Insider",
	siteTitlePossessive: "Stats Insider's",
	twitterUsername: "StatsInsider",
	facebookURL: "https://www.facebook.com/Stats-Insider-151036458905304/",
	instagramURL: "https://www.instagram.com/statsinsider/",
	discordURL: "",
	canonicalDomain: "https://www.statsinsider.com.au",
    ampDomain: /*"https://amp.statsinsider.com.au/",*/ undefined,
	defaultMetaDescription: "Visit Australia's leading sports analytics platform for the fans. Stats Insider simulates every match over 10,000 times to give you unprecedented insights into the sports you love.",
	defaultMetaImage: "https://cdn.ciphersports.io/images/default_meta.jpg",
	iconSet: {
		"favicon": "https://cdn.ciphersports.io/assets/statsinsider-icons/favicon.ico",
		"180": "https://cdn.ciphersports.io/assets/statsinsider-icons/icon-180x180.png",
		"16": "https://cdn.ciphersports.io/assets/statsinsider-icons/icon-16x16.png",
        "32": "https://cdn.ciphersports.io/assets/statsinsider-icons/icon-32x32.png",
        "96": "https://cdn.ciphersports.io/assets/statsinsider-icons/icon-96x96.png",
	},
	customBookmakerLinks: {
		"betmgm": "https://mediaserver.partners.roardigital.com/renderBanner.do?zoneId=1612073",
        "sugarhouse": "https://wlsugarhouseaffiliates.adsrv.eacdn.com/C.ashx?btag=a_1885b_675c_&affid=620&siteid=1885&adid=675&c=",
        "betrivers": "http://wlsugarhouseaffiliates.adsrv.eacdn.com/C.ashx?btag=a_3094b_750c_&affid=940&siteid=3094&adid=750&c=",
        "pointsbet_au": "https://stts.in/PointsBet-NRL",
        "william_hill": "https://wlwilliamhillus.adsrv.eacdn.com/C.ashx?btag=a_335b_3c_&affid=81&siteid=335&adid=3&c=",
        "fanduel": "https://sportsbook.fanduel.com",
        "bet365": "https://www.bet365.com/olp/open-account?affiliate=365_03369384",
        "topsport": "https://www.topsport.com.au/?promocode=insider",
		"bluebet": "https://www.bluebet.com.au/",
		"betfair": "https://ads.betfair.com.au/redirect.aspx?pid=4875650&bid=11198&utm_source=affiliates-statsinsider&utm_medium=display&utm_content=odds&utm_term=awareness",
	},
	ballIconFunction: (sportCode: string, colour: string): string => {
        if (!sportCode) return "";
		sportCode = sportCode.toUpperCase();

		// colour conversion
		if (colour !== "white" && colour !== "blue" && colour !== "black") {
			colour = "orange";
		}
		if (colour === "blue") {
			colour = "black";
		}
		return `https://cdn.ciphersports.io/assets/sport-icons/2022/${colour}/${sportCode}.svg`;
	},
	googleAdsConfig: {
        customerID: "135062774,22095247501",
        adSpaces: {
        	// "header": {
			// 	name: "statsinsider-header",
			// 	id: "div-gpt-ad-1665983645965-0"
			// },
			// "footer": {
			// 	name: "statsinsider-footer",
			// 	id: "div-gpt-ad-1665983673471-0"
			// },
            // "main-column": {
            //     name: "statsinsider-main-column",
            //     id: "div-gpt-ad-1664430222840-0"
            // },
            // "main-column2": {
            //     name: "statsinsider-main-column2",
            //     id: "div-gpt-ad-1664430314948-0"
            // },
            // "main-column3": {
            //     name: "statsinsider-main-column3",
            //     id: "div-gpt-ad-1664430330905-0"
            // },
            // "sidebar-short": {
            //     name: "statsinsider-sidebar-short",
            //     id: "div-gpt-ad-1664430347562-0"
            // },
            // "sidebar-multi": {
            //     name: "statsinsider-sidebar-multi",
            //     id: "div-gpt-ad-1664430362467-0"
            // },
            "anchor": {
                name: "statsinsider.anchor",
                id: "si-anchor"
            },
			"masthead": {
				name: "statsinsider.masthead",
				id: "masthead"
			},
			"home-POS1": {
				name: "statsinsider-home.POS1",
				id: "home-POS1"
			},
			"home-POS2": {
				name: "statsinsider-home.POS2",
				id: "home-POS2"
			},
			"home-POS3": {
				name: "statsinsider-home.POS3",
				id: "home-POS3"
			},
			"bestbets-POS1": {
				name: "statsinsider-bestbets.POS1",
				id: "bestbets-POS1"
			},
			"bestbets-POS2": {
				name: "statsinsider-bestbets.POS2",
				id: "bestbets-POS2"
			},
			"livenow-POS1": {
				name: "statsinsider-livenow.POS1",
				id: "livenow-POS1"
			},
			"livenow-POS2": {
				name: "statsinsider-livenow.POS2",
				id: "livenow-POS2"
			},
			"horseracing-POS1": {
				name: "statsinsider-horseracing.POS1",
				id: "horseracing-POS1"
			},
			"horseracing-POS2": {
				name: "statsinsider-horseracing.POS2",
				id: "horseracing-POS2"
			},
			"articles-POS1": {
				name: "statsinsider-articles.POS1",
				id: "articles-POS1"
			},
			"articles-POS2": {
				name: "statsinsider-articles.POS2",
				id: "articles-POS2"
			},
			"articles-POS3": {
				name: "statsinsider-articles.POS3",
				id: "articles-POS3"
			},
			"articles-POS4": {
				name: "statsinsider-articles.POS4",
				id: "articles-POS4"
			},
			"articles-POS5": {
				name: "statsinsider-articles.POS5",
				id: "articles-POS5"
			}

        }
    },
	aboutPageHTML: `
		<p>Stats Insider, Australia's leading predictive analytics website, provides Australian sports fans with innovative tools and content to maximise their enjoyment of major sporting events both domestically and internationally.&nbsp;</p>
		<p>Our goal is to transform the sports fan experience by providing data-driven content that is readily accessible to sports fans like us.</p>
		<p>Since our launch in 2018, we have provided predictions for over 15 sports leagues and are dedicated to pioneering advanced analytics content and discussion in Australian sport.</p>
		<p>Built in-house at our headquarters in Melbourne, Stats Insider's machine learning models take a variety of approaches to forecast the outcome of sporting events.</p>
		<p>In general, our models rely on a Monte Carlo approach to simulate each event 10,000 times to determine the range of outcomes and their likelihood.</p>
		<p>For most sports leagues, such as the <a href="https://www.statsinsider.com.au/sport-hub/nrl/schedule">NRL</a> and <a href="https://www.statsinsider.com.au/sport-hub/afl/schedule">AFL</a>, we first predict the performance of each individual player, based on hundreds of algorithmic factors for each sport, and then employ this range of player performances to run our match simulations.</p>
		<p>If a team wins 4,000 out of our 10,000 simulations, we estimate that they have a 40% chance of winning the upcoming match.</p>
		<p>Our models constantly update until an event commences, making the most of all the available information at that time, such as team lists when they are announced, weather conditions when they are known, shifts in the betting market as they happen, and more.</p>
		<p>In the end, you will use your own knowledge and the information we provide to engage with the sport &ndash; whether that's as a viewer, a punter, or in your office tipping competition.</p>
		<h2>Betting Information</h2>
		<p>Here at Stats Insider, you will find suggested bets and edges against available odds. This information should be used only as a guide to identify the best value plays in upcoming events. Here are some essential things to consider if you plan on using this information for your betting:</p>
		<h3>We empower users with data</h3>
		<p>Stats Insider is more like a form guide than a tipping service because our predictions move with the market and the available information. Therefore, the investment suggestions change based on the current best value. We offer information 24/7, and you decide what to do with it. This means that a team we recommended on Tuesday may not be recommended by the time they play on Saturday, although at the time, each recommendation was accurate to our model.</p>
		<h3>This is what we do</h3>
		<p>We have been constructing, developing, and testing our models for 15+ years and continue to enhance them daily to provide you with the best possible predictions every time.</p>
		<h3>Predictive, not historic</h3>
		<p>The fact that Geelong has won four of their last five games against Hawthorn does not necessarily imply they will win the next one. What if three of their key forwards are absent next week, or what if they play away and in the rain?</p>
		<p>Historical statistics do not predict future performance. Predictive analytics consider these factors and much more to provide a true prediction of how a game is likely to unfold based on 10,000 simulations of the upcoming match-up.</p>
		<h3>It's easy to find and free</h3>
		<p>The <a href="https://www.statsinsider.com.au/best-bets">Best Bets</a> page is the fastest way to see all our value plays for upcoming matches. You can also find detailed information on each value play on the respective match page.</p>
		<p>We believe that data-driven betting intelligence is vital for decision-making in betting rather than relying on emotions or intuition. By making this information entirely free to access, our goal is to assist punters in making more informed investments and, ultimately, gaining an edge over others in the market, wherever you bet.</p>
		<h3>Beat the bookies while they're behind the ball</h3>
		<p>At times, it may take the bookies a while to catch up, and by using Stats Insider, you have the ultimate head start to catch them out. The trick is timing &ndash; use Stats Insider predictions early in the week or when they go up to get ahead of the market.</p>
		<h2>The Data</h2>
		<h3>How do Stats Insider's predictive models work?</h3>
		<p>Each of Stats Insider's models use a similar approach when simulating a sporting event. Essentially, our data analysts aim to predict the distribution of a player's performance, such as how often a player will score zero, one, or two tries in the NRL, and how often they will have 10, 20, or 30 disposals in the AFL.</p>
		<p>Once these distributions have been predicted, a Monte Carlo approach is used to forecast the outcome of the match. This involves simulating the game 10,000 times using the player distributions to understand the likelihood of several outcomes, including how likely a team is to win, how likely there will be 40 points scored, or how likely any given player is to score the first goal.</p>
		<p>Once the simulations have been run, Stats Insider can compare the results to common betting markets and the odds available for punters and tippers. For example, if a team wins 6,520 out of 10,000 simulations, then Stats Insider will predict them to have a 65.2% chance of winning the match, which is fair odds of $1.53. If the team can be bet on at $2 with a bookmaker or on an exchange, then this would be listed as a suggested bet at the time.</p>
		<h3>How accurate are Stats Insider's predictions?</h3>
		<p>If Stats Insider predicts that a team has an 80% chance of winning, it does not mean that they will win or that Stats Insider thinks they will win. It means that if the team were to play this match 100 times, the given team would win 80 of them. This is a crucial difference and underlies everything that Stats Insider publishes: we provide data and insights.</p>
		<h3>Why do Stats Insider's predictions change?</h3>
		<p>If you visit the site on Monday and it highlights the Broncos as an investment worth considering, but by Saturday it is suggesting opposing them, do not be surprised. The models gain information all the time, for example, on Tuesday when NRL team lists are announced, on Thursday when it is announced that the hooker has picked up an injury, on Friday when one of the big betting syndicates hammers the price, and on Saturday morning when the BOM issues a weather alert for a severe thunderstorm. All these factors impact the possible outcomes of the match and therefore impact the models. Stats Insider's suggested investments may change frequently right up until game time.</p>
		<h3>How do Stats Insider's in-game simulations work?</h3>
		<p>Our&nbsp;<a href="https://www.statsinsider.com.au/live-now">live models</a> work in a very similar way to the pre-match ones. However, 10,000 simulations take a fair bit of time and computing power to run, and this cannot be done every minute during the game. Depending on the sport, 500 or 1,000 simulations of the rest of the game may be run. The approach is the same: if the rest of the game were played a certain number of times, how many times does each team win?</p>
		<h3>How do Stats Insider's futures tips work?</h3>
		<p>Our seasonal projections take two primary inputs: the individual match simulations, where each remaining match in the season is simulated 10,000 times, and the likelihood that a team will be impacted by injury to one or more of their key players. If a player has historically missed 25% of the season due to injury, or if they are in doubt for the coming week, the season simulations will capture this and force the team to 'play' some of those games without that player. As with the single event predictions, these probabilities are then lined up with the bookies' odds to determine if there is a bet to be made.</p>
		<h3>What should I do with the information?</h3>
		<p>Well, that's up to you. We place bets on many of our suggested investments each week. We also enter them into tipping contests and use the information to inform our fantasy decisions when picking our daily fantasy teams. Ultimately, it's your decision how to use the data we provide.</p>
	`,
	privacyPolicyHTML: `
		<div class="about-text">
			<p>
				In this Privacy Policy, 'us' 'we' or 'our' means Hypometer Technologies Pty Ltd (ABN 78 609
				507 744). We are committed to respecting your privacy. Our Privacy Policy sets outs out how
				we collect, use, store and disclose your personal information. While we are not bound by the
				Australian Privacy Principles contained in the Privacy Act this Privacy Policy reflects those
				principles as a matter of best practice.
			</p>
			
			<p>
				By providing personal information to us, you consent to our collection, use and disclosure of
				your personal information in accordance with this Privacy Policy and any other arrangements
				that apply between us. We may change our Privacy Policy from time to time by publishing changes
				to it on our website. We encourage you to check our website periodically to ensure that you are
				aware of our current Privacy Policy.
			</p>
			
			<p>
				Personal information includes information or an opinion about an individual that is reasonably
				identifiable. For example, this may include your name, age, gender, postcode and contact details.
				It may also include financial information, including your credit card information.
			</p>
		</div>
		<div class="about-text">
			<h2>What personal information do we collect?</h2>
			
			<p>We may collect the following types of personal information:</p>
			
			<ul>
				<li>name;</li>
				<li>mailing or street address;</li>
				<li>email address;</li>
				<li>telephone number and other contact details;</li>
				<li>age or date of birth;</li>
				<li>credit card information;</li>
				<li>your device ID, device type, geo-location information, computer and connection information,
					statistics on page views, traffic to and from the sites, ad data, IP address and standard
					web log information;
				</li>
				<li>details of the products and services we have provided to you or that you have enquired about,
					including any additional information necessary to deliver those products and services and
					respond to your enquiries;
				</li>
				<li>any additional information relating to you that you provide to us directly through our
					website or app or indirectly through your use of our website or app or online presence or
					through other websites or accounts from which you permit us to collect information;
				</li>
				<li>information you provide to us through customer surveys; or
				</li>
				<li>any other personal information that may be required in order to facilitate your dealings
					with us.
				</li>
			</ul>
			
			<p>We may collect these types of personal information either directly from you, or from third parties.
				We may collect this information when you:
			</p>
			
			<ul>
				<li>register on our website or app;
				</li>
				<li>communicate with us through correspondence, chats, email, or when you share information with
					us from other social applications, services or websites;
				</li>
				<li>interact with our sites, services, content and advertising; or
				</li>
				<li>invest in our business or enquire as to a potential purchase in our business.
				</li>
			</ul>
			
			<p>In addition, when you apply for a job or position with us we may collect certain information from
				you (including your name, contact details, working history and relevant records checks) from any
				recruitment consultant, your previous employers and others who may be able to provide information
				to us to assist in our decision on whether or not to make you an offer of employment or engage you
				under a contract. This Privacy Policy does not apply to acts and practices in relation to employee
				records of our current and former employees, which are exempt from the Privacy Act.
			</p>
		</div>

		<div class="about-text">
			<h2>Why do we collect, use and disclose personal information?</h2>
			
			<p>We may collect, hold, use and disclose your personal information for the following purposes:</p>
			
			<ul>
				<li>to enable you to access and use our website or services;</li>
				<li>to operate, protect, improve and optimise our website or services, business and our users’
					experience, such as to perform analytics, conduct research and for advertising and marketing;
				</li>
				<li>to send you service, support and administrative messages, reminders, technical notices, updates,
					security alerts, and information requested by you;
				</li>
				<li>to send you marketing and promotional messages and other information that may be of interest to
					you, including information sent by, or on behalf of, our business partners that we think you may
					find interesting;
				</li>
				<li>to administer rewards, surveys, contests, or other promotional activities or events sponsored
					or managed by us or our business partners;
				</li>
				<li>to comply with our legal obligations, resolve any disputes that we may have with any of our
					users, and enforce our agreements with third parties; and
				</li>
				<li>to consider your employment application.</li> 
			</ul>
			
			<p>We may also disclose your personal information to a trusted third party who also holds other information
				about you. This third party may combine that information in order to enable it and us to develop
				anonymised consumer insights so that we can better understand your preferences and interests,
				personalise your experience and enhance the products and services that you receive.
			</p>
		</div>

		<div class="about-text">
			<h2>Do we use your personal information for direct marketing?</h2>
			
			<p>We and/or our carefully selected business partners may send you direct marketing communications and
				information about our products and services. This may take the form of emails, SMS, mail or other forms
				of communication, in accordance with the <i>Spam Act</i> and the <i>Privacy Act</i>. You may opt-out of
				receiving marketing materials from us by contacting us using the details set out below or by using the
				opt-out facilities provided (e.g. an unsubscribe link).
			</p>
		</div>

		<div class="about-text">
			<h2>To whom do we disclose your personal information?</h2>
			
			<p>We may disclose personal information for the purposes described in this privacy policy to:</p>
			
			<ul>
				<li>our employees and related bodies corporate;</li>
				<li>third party suppliers and service providers (including providers for the operation of our websites
					and/or our business or in connection with providing our products and services to you);
				</li>
				<li>professional advisers, dealers and agents;</li>
				<li>payment systems operators (eg merchants receiving card payments);</li>
				<li>our existing or potential agents, business partners or partners;</li>
				<li>our sponsors or promoters of any competition that we conduct via our services;</li>
				<li>anyone to whom our assets or businesses (or any part of them) are transferred;</li>
				<li>specific third parties authorised by you to receive information held by us; and/or</li>
				<li>other persons, including government agencies, regulatory bodies and law enforcement agencies,
					or as required, authorised or permitted by law.
				</li>
			</ul>
		</div>

		<div class="about-text">
			<h2>Disclosure of personal information outside Australia</h2>
			
			<p>We may disclose personal information outside of Australia to entities that assist us in providing our
				services to you, including cloud storage providers, payment service providers, analytics services,
				and mail service providers located in Australia, USA, Ireland and Germany.
			</p>
			
			<p>When you provide your personal information to us, you consent to the disclosure of your information
				outside of Australia and acknowledge that we are not required to ensure that overseas recipients
				handle that personal information in compliance with Australian Privacy Law. We will, however, take
				reasonable steps to ensure that any overseas recipient will deal with such personal information in
				a way that is consistent with the Australian Privacy Principles.
			</p>
		</div>

		<div class="about-text">
			<h2>Using our website and cookies</h2>
			
			<p>We may collect personal information about you when you use and access our website.</p>
			
			<p>While we do not use browsing information to identify you personally, we may record certain information
				about your use of our website, such as which pages you visit, the time and date of your visit and the
				internet protocol address assigned to your computer.
			</p>
			
			<p>We may also use 'cookies' or other similar tracking technologies on our website that help us track your
				website usage and remember your preferences. Cookies are small files that store information on your
				computer, TV, mobile phone or other device. They enable the entity that put the cookie on your device
				to recognise you across different websites, services, devices and/or browsing sessions. You can disable
				cookies through your internet browser but our websites may not work as intended for you if you do so.
			</p>
			
			<p>We may also use cookies to enable us to collect data that may include personal information. For example,
				where a cookie is linked to your account, it will be considered personal information under the Privacy Act.
				We will handle any personal information collected by cookies in the same way that we handle all other
				personal information as described in this Privacy Policy. Our third party service providers may also utilise
				cookies via our site to assist us in providing our services to you.
			</p>
		</div>

		<div class="about-text">
			<h2>Security</h2>
			
			<p>We may hold your personal information in either electronic or hard copy form. We take reasonable steps to
				protect your personal information from misuse, interference and loss, as well as unauthorised access,
				modification or disclosure and we use a number of physical, administrative, personnel and technical
				measures to protect your personal information. For example, we restrict physical access to our offices,
				implement firewalls and utilise password protection and two-factor authentication where appropriate.
				However, we cannot guarantee the security of your personal information.
			</p>
		</div>

		<div class="about-text">
			<h2>Links</h2>
			
			<p>Our website may contain links to websites operated by third parties. Those links are provided for
				convenience and may not remain current or be maintained. Unless expressly stated otherwise, we are not
				responsible for the privacy practices of, or any content on, those linked websites, and have no control
				over or rights in those linked websites. The privacy policies that apply to those other websites may
				differ substantially from our Privacy Policy, so we encourage individuals to read them before using
				those websites.
			</p>
		</div>

		<div class="about-text">
			<h2>Accessing or correcting your personal information</h2>
			
			<p>You can access the personal information we hold about you by contacting us using the information below.
				Sometimes, we may not be able to provide you with access to all of your personal information and,
				where this is the case, we will tell you why. We may also need to verify your identity when you request
				your personal information.
			</p>
			
			<p>If you think that any personal information we hold about you is inaccurate, please contact us and we will
				take reasonable steps to ensure that it is corrected.
			</p>
		</div>

		<div class="about-text">
			<h2>Making a complaint</h2>
			
			<p>If you think we have breached the Privacy Act, or you wish to make a complaint about the way we have
				handled your personal information, you can contact us using the details set out below. Please include
				your name, email address and/or telephone number and clearly describe your complaint. We will acknowledge
				your complaint and respond to you regarding your complaint within a reasonable period of time. If you think
				that we have failed to resolve the complaint satisfactorily, we will provide you with information about the
				further steps you can take.
			</p>
		</div>

		<div class="about-text">
			<h2>Contact Us</h2>
			
			<p>For further information about our Privacy Policy or practices, or to access or correct your personal
				information, or make a complaint, please contact us using the details set out below:
			</p>
			
			<p>Privacy Officer<br/>
				Level 7, 51 Langridge Street, Collingwood, VIC 3066 Australia<br/>
				privacy@thehypometer.com
			</p>
		</div>

		<div class="about-text">
			<p><b>Effective:</b> 2 November 2018</p>
		</div>
	`,
	termsPageHTML: `
		<div class="about-text">
				
				
			<p>Thank you for visiting our website. This website is owned and operated by Hypometer Technologies Pty Ltd ACN 609 507 74. By accessing and/or using this website and related services, you agree to these Terms and Conditions, which include our Privacy Policy (available at <a href="https://www.statsinsider.com.au/privacy">https://www.statsinsider.com.au/privacy</a>) (<strong><em>Terms</em></strong>). You should review our Privacy Policy and these Terms carefully and immediately cease using our website if you do not agree to these Terms.</p>
			<p>In these Terms, ‘us’, ‘we’ and ‘our’ means Hypometer Technologies Pty Ltd ACN 609 507 74</p>
			<h2>Registration</h2>
			<p>You may need to be a registered member to access certain features of our website.</p>
			<p>When you register and activate your account, you will provide us with personal information such as your name, email address, mailing address and other personal information. You must ensure that this information is accurate and current. We will handle all personal information we collect in accordance with our Privacy Policy (<a href="https://www.statsinsider.com.au/privacy">https://www.statsinsider.com.au/privacy</a>)</p>
			<p>When you register and activate your account, we will provide you with a user name and password. You are responsible for keeping this user name and password secure and are responsible for all use and activity carried out under this user name.</p>
			<p>To create an account, you must be:</p>
			<ul>
				<p>At least 18 years of age;</p>
				<p>Possess the legal right and ability to enter into a legally binding agreement with us;</p>
				<p>Agree and warrant to use the website in accordance with these Terms.</p>
			</ul>
			<h2>Collection Notice</h2>
			<p>We collect personal information about you in order to process your registration and provide you with requested products, and for purposes otherwise set out in our Privacy Policy at <a href="https://www.statsinsider.com.au/privacy">https://www.statsinsider.com.au/privacy</a>. We may disclose that information to third parties that help us deliver our services (including information technology suppliers, communication suppliers and our business partners) or as required by law. If you do not provide this information, we may not be able to provide all of our products to you. We may also disclose your personal information to recipients that are located outside of Australia, including to membership management and newsletter services located in the Untied States of America.</p>
			<p>Our Privacy Policy explains: (i) how we store and use, and how you may access and correct your personal information; (ii) how you can lodge a complaint regarding the handling of your personal information; and (iii) how we will handle any complaint. If you would like any further information about our privacy policies or practices, please contact us at <a href="mailto:support@statsinsider.com.au">support@statsinsider.com.au</a></p>
			<p>By providing your personal information to us, you consent to the collection, use, storage and disclosure of that information as described in the Privacy Policy and these Terms.</p>
			<h2>Accuracy, completeness and timeliness of information</h2>
			<p>The information on our website is not comprehensive and is intended to provide a summary of the subject matter covered. While we use all reasonable attempts to ensure the accuracy and completeness of the information on our website, to the extent permitted by law, including the Australian Consumer Law, we make no warranty regarding the information on this website. You should monitor any changes to the information contained on this website.</p>
			<p>We are not liable to you or anyone else if interference with or damage to your computer systems occurs in connection with the use of this website or a linked website. You must take your own precautions to ensure that whatever you select for your use from our website is free of viruses or anything else (such as worms or Trojan horses) that may interfere with or damage the operations of your computer systems.</p>
			<p>We may, from time to time and without notice, change or add to the website (including the Terms) or the information, products or services described in it. However, we do not undertake to keep the website updated. We are not liable to you or anyone else if errors occur in the information on the website or if that information is not up-to-date.</p>
			<h2>Promotions and competitions</h2>
			<p>For certain campaigns, promotions or contests, additional terms and conditions may apply. If you want to participate in such a campaign, promotion or contest, you need to agree to the relevant terms and conditions applicable to that campaign, promotion or contest. In case of any inconsistency between such terms and conditions and these Terms, those terms and conditions will prevail.</p>
			<p>Customers are strictly limited to one&nbsp;(1)&nbsp;‘free trial’ account per household and/or IP address.</p>
			<h2>Purchases</h2>
			<p>The information and opinions available for purchase on this website are for informational purposes only. Hypometer Technologies Pty Ltd shall not be liable to you or any other person for any reliance upon, or direct, indirect or consequential losses, damages, or costs suffered in relation to any of the information and/or selections supplied by any of our services. Any monetary investment based on information and/or services displayed on the Stats Insider website is at the sole discretion of the purchaser or investor. Past performance is no guarantee of future results and your own results will vary in a positive or negative manner relative to those published on this site depending on your own efforts. Memberships are not transferable in any manner regardless of performance, personnel or change of mind.</p>
			<p>You are not entitled to a refund for any unused portion of your membership should you decide to cancel.</p>
			<p>Stats Insider makes no guarantee of availability of the website services and accepts no responsibility for interrupted service of any kind or for failure to provide information.</p>
			<p>We reserve the right to discontinue services or revoke your access to information and/or selections.</p>
			<p>In Australia, our goods and services come with guarantees that cannot be excluded under the Australian Consumer Law. Nothing in these Terms and Conditions purports to modify or exclude the conditions, warranties and undertakings, and other legal rights, under the Australian Competition and Consumer Act and other laws. Any and all other warranties or conditions which are not guaranteed by the Australian Consumer Law or the Competition and Consumer Regulation Act 2013 are expressly excluded where permitted, including liability for incidental or consequential damages caused by breach of any express or implied warranty or condition.</p>
			<h2>Linked sites</h2>
			<p>Our website may contain links to websites operated by third parties. Those links are provided for convenience and may not remain current or be maintained. Unless expressly stated otherwise, we do not endorse and are not responsible for the content on those linked websites and have no control over or rights in those linked websites.</p>
			<h2>Intellectual property rights</h2>
			<p>Unless otherwise indicated, we own or license from third parties all rights, title and interest (including copyright, designs, patents, trademarks and other intellectual property rights) in this website and in all of the material (including all text, graphics, logos, audio and software) made available on this website (<em>Content</em>).</p>
			<p>Your use of this website and use of and access to any Content does not grant or transfer any rights, title or interest to you in relation to this website or the Content. However we do grant you a licence to access the website and view the Content on the terms and conditions set out in this Agreement and, where applicable, as expressly authorised by us and/or our third party licensors.</p>
			<p>Any reproduction or redistribution of this website or the Content is prohibited and may result in civil and criminal penalties. In addition, you must not copy the Content to any other server, location or support for publication, reproduction or distribution is expressly prohibited.</p>
			<p>All other use, copying or reproduction of this website, the Content or any part of it is prohibited, except to the extent permitted by law.</p>
			<h2>No commercial use</h2>
			<p>This website is for your personal, non-commercial use only. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, commercially exploit, create derivative works from, transfer, or sell any Content, software, products or services contained within this website. You may not use this website, or any of its Content, to further any commercial purpose, including any advertising or advertising revenue generation activity on your own website.</p>
			<h2>Unacceptable activity</h2>
			<p>You must not do any act that we would deem to be inappropriate, is unlawful or is prohibited by any laws applicable to our website, including but not limited to:</p>
			<ul>
				<p>Any act that would constitute a breach of either the privacy (including uploading private or personal information without an individual’s consent) or any other of the legal rights of individuals;</p>
				<p>Using this website to defame or libel us, our employees or other individuals;</p>
				<p>Uploading files that contain viruses that may cause damage to our property or the property of other individuals;</p>
				<p>Posting or transmitting to this website any non-authorised material including, but not limited to, material that is, in our opinion, likely to cause annoyance, or which is defamatory, racist, obscene, threatening, pornographic or otherwise or which is detrimental to or in violation of our systems or a third party’s systems or network security.</p>
			</ul>
			<p>If we allow you to post any information to our website, we have the right to take down this information at our sole discretion and without notice.</p>
			<h2>Warranties and disclaimers</h2>
			<p>To the maximum extent permitted by law, including the Australian Consumer Law, we make no warranties or representations about this website or the Content, including but not limited to warranties or representations that they will be complete, accurate or up-to-date, that access will be uninterrupted or error-free or free from viruses, or that this website will be secure.</p>
			<p>We reserve the right to restrict, suspend or terminate without notice your access to this website, any Content, or any feature of this website at any time without notice and we will not be responsible for any loss, cost, damage or liability that may arise as a result.</p>
			<h2>Liability</h2>
			<p>To the maximum extent permitted by law, including the Australian Consumer Law, in no event shall we be liable for any direct and indirect loss, damage or expense – irrespective of the manner in which it occurs – which may be suffered due to your use of our website and/or the information or materials contained on it, or as a result of the inaccessibility of this website and/or the fact that certain information or materials contained on it are incorrect, incomplete or not up-to-date.</p>
			<h2>Jurisdiction and governing law</h2>
			<p>Your use of the website and these Terms are governed by the law of Queensland and you submit to the non-exclusive jurisdiction of the courts exercising jurisdiction in Queensland.</p>
		</div>
	`,
	footerLinkColumnPages: [
        {title: "About Us", href: "/about-us", queryParams: {}},
        {title: "Contact Us", href: "mailto:support@statsinsider.com.au", queryParams: {}, strictHref: true},
        {title: "Writers", href: "/news/authors", queryParams: {}},
        {title: "Privacy Policy", href: "/privacy-policy", queryParams: {}},
        {title: "Terms of Service", href: "/terms-of-service", queryParams: {}},
        {title: "Sitemap", href: "/sitemap", queryParams: {}},
        {title: "Live Scores", href: "/live-now", queryParams: {}},
    ],
	footerDataProviders: [
		// {name: "STATS", logoURL: "https://cdn.ciphersports.io/assets/stats_60h.png"},
		// {name: "Australian Associated Press", logoURL: "https://cdn.ciphersports.io/assets/aap-logo.png"},
		{name: "SportRadar", logoURL: "https://cdn.ciphersports.io/assets/sportsradar-logo_60h.png"},
		// {name: "As Used By TAB", logoURL: "https://cdn.ciphersports.io/assets/asusedbytablogo.png"},
	],
	footerDisclaimerHTML: `
		<p>© 2015-2024 Hypometer Technologies Pty Ltd (ABN 78 609 507 744). Proudly part of Cipher Sports Technology Group, Level 7, 51 Langridge Street, Collingwood, VIC 3066.</p>
		<p>Stats Insider is in no way affiliated with or endorsed by the AFL, NRL, or any other sporting league or club.<br>
		Past performance is not always indicative of future performance.</p>
	`,
	optionalFeatures: [
		"match-value-summary",
		"tipping",
		"score-strip",
		// "taboola",
		"auctioned-ads",
	],
    newsletterProvider: undefined,
	homePageLayout: "two-column",
	contactEmailAddress: "support@statsinsider.com.au",
	matchFirstTeam: "home",
	fontFamily: "\"proxima-nova\", sans-serif",
	fontImport: "https://use.typekit.net/fgm5bdf.css",
	blogFontFamily: undefined,
	blogFontImport: undefined,
	onetrustID: undefined,
	smartlookID: "62ea7900ae2a66fe35fcd0e27c17805bc942df79",
	colourValues: {
		"background-tone": "255 255 255",
		"foreground-tone": "0 0 0",

		"overlay-tone": "0 0 0",
		
		"grey1": "237 240 243",
		"grey1-contrast": "0 0 0",
		"grey2": "128 128 128",
		"grey2-contrast": "0 0 0",
		"grey3": "151 151 151",
		"grey3-contrast": "0 0 0",
		"grey5": "122 122 141",
		"grey5-contrast": "0 0 0",
		"grey6": "72 90 114",
		"grey6-contrast": "255 255 255",
		"grey7": "78 78 78",
		"grey7-contrast": "255 255 255",
		"grey8": "248 248 248",
		"grey8-contrast": "0 0 0",
		"grey9": "216 216 216",
		"grey9-contrast": "0 0 0",
		"grey10": "99 113 135",
		"grey10-contrast": "255 255 255",
		"grey11": "224 224 224",
		"grey11-contrast": "0 0 0",
		"grey12": "231 231 231",
		"grey12-contrast": "0 0 0",
		
		"orange": "249 85 58",
		"orange-contrast": "255 255 255",
		
		"blue2": "41 34 48",
		"blue2-contrast": "255 255 255",
		
		"blue3": "1 132 232",
		"blue3-contrast": "255 255 255",
		
		"blue4": "73 68 78",
		"blue4-contrast": "255 255 255",
		
		"blue5": "0 0 238",
		"blue5-contrast": "255 255 255",
		
		"green1": "7 132 66",
		"green1-contrast": "255 255 255",
		
		"green3": "0 136 57",
		"green3-contrast": "255 255 255",
		
		"red1": "216 22 69",
		"red1-contrast": "0 0 0",
		
		"pink2": "255 141 141",
		"pink2-contrast": "0 0 0",
		
		"purple1": "103 48 239",
		"purple1-contrast": "255 255 255",
		
		"yellow1": "239 227 0",
		"yellow1-contrast": "0 0 0",
		
		"text-default": "0 0 0",
		
		
		"highlight": "249 85 58",
		"highlight-contrast": "255 255 255",
		
		
		"shadow1": "224 224 224",
		
		"border1": "216 216 216",
		"border2": "151 151 151",
		"border3": "78 78 78",
		"border4": "237 240 243",
		"border5": "231 231 231",
		"border6": "72 90 114",
		
		
		
		
		"body-text2": "98 112 135",
		"body-text3": "122 138 159",
		"body-text4": "192 192 192",
		"button1": "248 248 248",
		"button1-contrast": "0 0 0",
		"button2": "253 253 253",
		"button2-contrast": "0 0 0",
		"button3": "224 224 224",
		"button3-contrast": "0 0 0",
		"upcoming-blue": "23 51 117",
		"upcoming-blue-contrast": "255 255 255",
		"live-green": "0 128 79",
		"live-green-contrast": "255 255 255",
		"final-red": "216 22 69",
		"final-red-contrast": "255 255 255",
		"paused-grey": "98 112 135",
		"paused-grey-contrast": "255 255 255",
		"draw-grey": "124 138 167",
		"shortlist-gold": "248 214 78",
		
		"left-team-colour": "249 85 58",
		"right-team-colour": "41 34 48",
		
		"blue2-lighter": "66 55 78",
		"highlight-lighter": "250 128 107",
		"block-toggle-selected": "249 85 58",
		"block-toggle-selected-contrast": "255 255 255",
		"prob-progression-background": "103 48 239",

		"highlight-secondary": "239 227 0",
		"highlight-secondary-contrast": "0 0 0",

		"dark-primary": "41 34 48",
		"dark-primary-contrast": "255 255 255",

		"dark-secondary": "73 68 78",
		"dark-secondary-contrast": "255 255 255",
		
		"dark-primary-lighter": "66 55 78",

		"article-category-label": "103 48 239",

		"highlight-darker": "199 68 46",
	},
	manifestPath: "./assets/manifests/statsinsider.json",
    adsFilePath: "./assets/ads-files/statsinsider.txt",
	additionalNavOptions: [
        // {title: "Horse Racing", routerLink: "/news", queryParams: {sport: "racing"}, subLinks: undefined},
    ],
	landingPageSidebar: false,
	bestOffersTitle: undefined,
	newsletterPromptTitle: undefined,
	newsletterPromptSubtitle: undefined,
	newsletterPromptTermsText: undefined,
    bestBetsFormat: "stats-insider",
	googleSiteVerificationCode: undefined,
};
